<template>
  <div>
    <div v-if="ModalSettings.ShowModal == true" id="standardModal" class="modal" role="dialog" >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 v-if="ModalSettings.EmptyFields || ModalSettings.InflationMoreThenReturn" class="modal-title">Fel värden!</h4>
            <h4 v-if="ModalSettings.StartValueText || ModalSettings.TimeText || ModalSettings.ReturnText || ModalSettings.MonthlySavingsText || ModalSettings.InflationText" class="modal-title">Information</h4>
          </div>
          <div class="modal-body">
            <p v-if="ModalSettings.EmptyFields"><i>Startvärde</i>, <i>Tid</i> samt <i>Avkastning per år</i> är obligatoriska för att beräkningen ska kunna genomföras.</p>          
            <p v-if="ModalSettings.InflationMoreThenReturn"><i>Avkastning per år</i> måste vara större eller lika med <i>Inflation</i> för att beräkningen ska kunna genomföras.</p>          
            <p v-if="ModalSettings.StartValueText">Startvärde motsvarar det beloppet du har ihopsparat i dagsläget.</p>
            <p v-if="ModalSettings.TimeText">Antal år du kan låta pengarna ligga stilla utan att ta ut dem. Så längre du kan låta pengarna vara desto mer växer dem. Testa att ändra mellan 10, 20 och 30 år för att se skillnaden.</p>
            <p v-if="ModalSettings.ReturnText">Hur många procent som pengarna förväntas växa med per år. Exempel på värden du kan testa med: Vanligt bankkonto: 1%, Sparkonto 2%, Börsen 8%.</p>
            <p v-if="ModalSettings.MonthlySavingsText">Hur mycket pengar som du kan spara varje månad. Exempel på värden att testa med: Att byta från utelunch till matlåda blir en besparing på ungefär 1 120 kr per månad.</p>
            <p v-if="ModalSettings.InflationText">Inflation innebär att priserna stiger så att man kan köpa färre varor och tjänster för samma mängd pengar. Inflationen urholkar pengarnas värde och köpkraft.</p>
          </div>
          <div class="modal-footer">
             <button @click="resetModal()">Ok</button>          
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../src/styles/_layout.scss";
import "../../src/styles/_modal.scss";

export default {
  name: "Modal",

  data: function() {
    return {};
  },

  props: {
    ModalSettings: {
      ShowModal: false,
    },
  },

   methods: {
    resetModal: function() {
      this.ModalSettings.ShowModal = false;
      this.ModalSettings.EmptyFields = false;
      this.ModalSettings.InflationMoreThenReturn = false;   
      this.ModalSettings.StartValueText = false; 
      this.ModalSettings.TimeText = false;   
      this.ModalSettings.ReturnText = false;   
      this.ModalSettings.MonthlySavingsText = false;   
      this.ModalSettings.InflationText = false;
    },    
  },

};
</script>
