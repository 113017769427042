<template>
    <div class="fortune-calculator">
        <div class="container top-space-to-header">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <H1 class="heading">Förväntad avkastning över tid</H1>
                    <div class="separator mt-3"></div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="input-container">
                                <div class="input-text-row">
                                    <p class="input-text">Startvärde</p>
                                    <img src="../assets/images/fortune_calculator/question-32.png" @click="manageModal('StartValue')" />
                                </div>
                                <input type="text" class="input-elements" v-model="FortuneModel.StartValue" /> <!--placeholder="300000"-->
                                <div class="input-text-row">
                                    <p class="input-text">Tid</p>
                                    <img src="../assets/images/fortune_calculator/question-32.png" @click="manageModal('Time')" />
                                </div>
                                <input class="input-elements" v-model="FortuneModel.Time" /> <!--placeholder="20"-->
                                <div class="input-text-row">
                                    <p class="input-text">Avkastning per år</p>
                                    <img src="../assets/images/fortune_calculator/question-32.png" @click="manageModal('Return')" />
                                </div>
                                <input class="input-elements" v-model="FortuneModel.Return" /> <!--placeholder="10"-->
                                <div class="input-text-row">
                                    <p class="input-text">Månadssparande</p>
                                    <img src="../assets/images/fortune_calculator/question-32.png" @click="manageModal('MonthlySavings')" />
                                </div>
                                <input class="input-elements" v-model="FortuneModel.MonthlySavings" /> <!--placeholder="5000"-->
                                <div class="input-text-row">
                                    <p class="input-text">Inflation</p>
                                    <img src="../assets/images/fortune_calculator/question-32.png" @click="manageModal('Inflation')" />
                                </div>
                                <input class="input-elements" v-model="FortuneModel.Inflation" />  <!--placeholder="2" -->
                                <button class="input-elements mb-5" type="submit" @click="getFortune()">Avkasta</button>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-12">
                            <div title="Så mycket blev det :)" id="fortune" class="fortune">{{ Fortune }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal :ModalSettings="ModalSettings"></Modal>
    </div>
</template>

<script>
    import Modal from "@/components/Modal";
    import "../../src/styles/_layout.scss";
    import "../../src/styles/_fortune-calculator.scss";

    const axios = require("axios");

    export default {
        name: "FortuneCalculator",

        components: {
            Modal,
        },

        data: function () {
            return {
                FortuneModel: {
                    StartValue: "",
                    Time: "",
                    Return: "",
                    MonthlySavings: "",
                    Inflation: "",
                },
                DeliveryModel: {
                    StartValue: "",
                    Time: "",
                    Return: "",
                    MonthlySavings: "",
                    Inflation: "",
                },
                Fortune: "0 kr",
                ShowFortune: false,
                baseUrl: process.env.VUE_APP_WEB_API,
                ModalSettings: {
                    ShowModal: false,
                    ModalText: "",
                    EmptyFields: false,
                    InflationMoreThenReturn: false,
                    StartValueText: false,
                    TimeText: false,
                    ReturnText: false,
                    MonthlySavingsText: false,
                    InflationText: false,
                },
            };
        },

        methods: {
            getFortune: function () {
                document.getElementById("fortune").style.display = "none"
                if (this.FortuneModel.StartValue == "" || this.FortuneModel.Time == "" || this.FortuneModel.Return == "") {
                    this.ModalSettings.EmptyFields = true;
                    this.ModalSettings.ShowModal = true;
                    return;
                }
                if (Number(this.FortuneModel.Return) < Number(this.FortuneModel.Inflation)) {
                    this.ModalSettings.InflationMoreThenReturn = true;
                    this.ModalSettings.ShowModal = true;
                    return;
                }
                this.DeliveryModel.StartValue = this.FortuneModel.StartValue.replace(/\s/g, "");
                this.DeliveryModel.Time = this.FortuneModel.Time.replace(/\s/g, "");
                this.DeliveryModel.Return = this.FortuneModel.Return.replace(/\s/g, "");
                this.DeliveryModel.MonthlySavings = this.FortuneModel.MonthlySavings.replace(/\s/g, "");
                this.DeliveryModel.Inflation = this.FortuneModel.Inflation.replace(/\s/g, "");
                var self = this;
                //self.ShowSpinner = true;
                axios
                    .post(self.baseUrl + "api/FortuneCalculator/GetFortune", self.DeliveryModel)
                    .then(function (response) {
                        self.Fortune = response.data.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ") + " kr";
                        //self.ShowSpinner = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.error = true;
                        alert("error");
                    });
                this.animateFortune();
            },

            validateInput: function (input) {
                var noBlanks = input.replace(/\s/g, "");
                var onlyDigits = noBlanks.replace(/[^\d.]+/g, "");
                return onlyDigits.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ");
            },

            manageModal: function (field) {
                if (field == "StartValue") {
                    this.ModalSettings.StartValueText = true;
                }
                if (field == "Time") {
                    this.ModalSettings.TimeText = true;
                }
                if (field == "Return") {
                    this.ModalSettings.ReturnText = true;
                }
                if (field == "MonthlySavings") {
                    this.ModalSettings.MonthlySavingsText = true;
                }
                if (field == "Inflation") {
                    this.ModalSettings.InflationText = true;
                }
                this.ModalSettings.ShowModal = true;
            },

            animateFortune: function () {
                this.ShowFortune = true
                document.getElementById("fortune").style.display = "block"
            }
        },

        watch: {
            "FortuneModel.StartValue": function (value) {
                this.FortuneModel.StartValue = this.validateInput(value);
            },
            "FortuneModel.Time": function (value) {
                this.FortuneModel.Time = this.validateInput(value);
            },
            "FortuneModel.Return": function (value) {
                this.FortuneModel.Return = this.validateInput(value);
            },
            "FortuneModel.MonthlySavings": function (value) {
                this.FortuneModel.MonthlySavings = this.validateInput(value);
            },
            "FortuneModel.Inflation": function (value) {
                this.FortuneModel.Inflation = this.validateInput(value);
            },
        },

        /*mounted() {
          this.getFortune();
        },*/
    };
</script>
